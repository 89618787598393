import React from 'react'
import styled from 'styled-components'

import { ReactComponent as CheckMark } from 'assets/green-check.svg'

import Form from './Registration'

export default ({ title, textBlock1, list, textBlock2 }) => (
  <Container>
    <Form />
    <TextContainer>
      <Title>{title}</Title>
      <Divider />
      <Text>{textBlock1}</Text>
      {list.map((item, i) => {
        return (
          <ListItemContainer key={i}>
            <Check />
            <ListItem>{item.item}</ListItem>
          </ListItemContainer>
        )
      })}
      <Text>{textBlock2}</Text>
    </TextContainer>
  </Container>
)
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f5f5f5;
  padding-bottom: 70px;

  @media (max-width: ${props => props.theme.desktop}px) {
    flex-direction: column-reverse;
    padding-bottom: 30px;
  }
`
const TextContainer = styled.div`
  width: 40%;
  padding: 15px 30px 0;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 100%;
  }
`
const Title = styled.h4`
  width: 100%;
  font-size: 20pt;
  line-height: 24pt;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};
`
const Divider = styled.div`
  margin: 15px 0;
  width: 60px;
  min-height: 4px;
  background-color: ${props => props.theme.primaryColor};
`
const Text = styled.p`
  margin: 20px 0;
  width: 100%;
  font-size: 14pt;
  line-height: 22pt;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.demi};
`
const ListItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  &:last-of-type {
    margin-bottom: 30px;
  }
`
const Check = styled(CheckMark)`
  min-width: 10%;
  min-height: 20px;
`
const ListItem = styled.h4`
  margin: 5px 10px;
  width: 80%;
  font-size: 14pt;
  line-height: 24pt;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};
`
