import React from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import Yup from 'yup'

export default ({ onSubmitted }) => (
  <Formik
    initialValues={{
      firstName: '',
      lastName: '',
      carrierName: '',
      nmftaScacCode: '',
      dotNumber: '',
      mcNumber: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      email: '',
      phone: '',
      // modes
      tlt: false,
      truckload: false,
      ocean: false,
      intermodal: false,
      expedited: false
    }}
    validationSchema={Yup.object().shape({
      firstName: Yup.string().required('Please enter your name.'),
      lastName: Yup.string().required('Please enter your name.'),
      carrierName: Yup.string().required(
        'Please enter the name of your company.'
      ),
      nmftaScacCode: Yup.string().required('NMFTA SCAC Code is required.'),
      dotNumber: Yup.string().required('DOT Number is required.'),
      mcNumber: Yup.string().required('MC Number is required.'),
      address: Yup.string().required('Address is required.'),
      city: Yup.string().required('City is required.'),
      state: Yup.string().required('State is required.'),
      zipcode: Yup.string().required('Zipcode is required.'),
      email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Please provide an email.'),
      phone: Yup.string().required('Please enter your phone number.'),
      // modes
      ltl: Yup.bool(),
      truckload: Yup.bool(),
      ocean: Yup.bool(),
      intermodal: Yup.bool(),
      expedited: Yup.bool()
    })}
    onSubmit={(values, { setSubmitting }) => {
      fetch('/.netlify/functions/partner-carrier', {
        method: 'POST',
        body: JSON.stringify(values)
      })
        .then(res => res.json())
        .then(response => {
          setSubmitting(false)
          onSubmitted && onSubmitted()
        })
        .catch(error => {
          setSubmitting(false)
          alert('Unexpected server error. Please try again.')
        })
    }}
    render={({ values, errors, touched, isSubmitting }) => (
      <FormContainer>
        <InputGroup>
          <InputContainer>
            <Input name="firstName" placeholder="First Name" />
            <ErrorMessage>{touched.firstName && errors.firstName}</ErrorMessage>
          </InputContainer>
          <InputContainer>
            <Input name="lastName" placeholder="Last Name" />
            <ErrorMessage>{touched.lastName && errors.lastName}</ErrorMessage>
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <Input name="carrierName" placeholder="Carrier Name" />
            <ErrorMessage>
              {touched.carrierName && errors.carrierName}
            </ErrorMessage>
          </InputContainer>
          <InputContainer>
            <Input name="nmftaScacCode" placeholder="NMFTA SCAC Code" />
            <ErrorMessage>
              {touched.nmftaScacCode && errors.nmftaScacCode}
            </ErrorMessage>
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <Input name="dotNumber" placeholder="DOT Number" />
            <ErrorMessage>{touched.dotNumber && errors.dotNumber}</ErrorMessage>
          </InputContainer>
          <InputContainer>
            <Input name="mcNumber" placeholder="MC Number" />
            <ErrorMessage>{touched.mcNumber && errors.mcNumber}</ErrorMessage>
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <Input name="address" placeholder="Street Address" />
            <ErrorMessage>{touched.address && errors.address}</ErrorMessage>
          </InputContainer>
          <InputContainer>
            <Input name="city" placeholder="City" />
            <ErrorMessage>{touched.city && errors.city}</ErrorMessage>
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <Input name="state" placeholder="State" />
            <ErrorMessage>{touched.state && errors.state}</ErrorMessage>
          </InputContainer>
          <InputContainer>
            <Input name="zipcode" placeholder="Zipcode" />
            <ErrorMessage>{touched.zipcode && errors.zipcode}</ErrorMessage>
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <Input name="email" placeholder="Email Address" />
            <ErrorMessage>{touched.email && errors.email}</ErrorMessage>
          </InputContainer>
          <InputContainer>
            <Input name="phone" placeholder="Phone Number" />
            <ErrorMessage>{touched.phone && errors.phone}</ErrorMessage>
          </InputContainer>
        </InputGroup>
        <Text>Interested Modes</Text>
        <Checkboxes>
          <CheckboxContainer>
            <Checkbox name="ltl" />
            <CheckboxLabel>LTL</CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <Checkbox name="truckload" />
            <CheckboxLabel>Truckload</CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <Checkbox name="ocean" />
            <CheckboxLabel>Ocean</CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <Checkbox name="intermodal" />
            <CheckboxLabel>Intermodal</CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <Checkbox name="expedited" />
            <CheckboxLabel>Expedited</CheckboxLabel>
          </CheckboxContainer>
        </Checkboxes>
        <Button disabled={isSubmitting}>SUBMIT</Button>
      </FormContainer>
    )}
  />
)

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.tablet}px) {
    flex-direction: column;
  }
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 100%;
  }
`
const Input = styled(Field)`
  border: solid ${props => props.theme.primaryColor} 1px;
  border-radius: 5px;
  padding: 15px 10px 11px 10px;
  margin: 10px 0;
  background-color: white;
  transition-duration: 0.5s;

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-style: italic;
  }

  &:focus {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    &::placeholder {
      color: transparent;
    }
  }
`
const Text = styled.h4`
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.demi};
  font-size: 14pt;
`
const ErrorMessage = styled.div`
  font-size: 10pt;
  height: 13px;
  color: red;
`
const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${props => props.theme.tablet}px) {
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px;
  position: relative;
`
const CheckboxLabel = styled.label`
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.demi};
  font-size: 14pt;
`
const Checkbox = styled(Field).attrs({
  type: 'checkbox'
})`
  margin-right: 8px;
  width: 25px;
  height: 25px;
  background: white;
  cursor: pointer;
  padding: 3px;
`
const Button = styled.button`
  all: unset;
  font-size: 16pt;
  padding: 20px 45px 15px;
  background-color: ${props => props.theme.accentColor1};
  font-weight: ${props => props.theme.demi};
  color: ${props => props.theme.primaryColor};
  align-self: center;
  cursor: pointer;
  border-radius: 5px;
  transition-duration: 0.5s;
  border: 2px solid ${props => props.theme.primaryColor};
  margin: 30px 0 20px;

  &:hover {
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.accentColor1};
  }
`
