import React, { Component } from 'react'
import styled from 'styled-components'

import Form from './Form'
import Success from './Success'

export default class Contact extends Component {
  state = { submitted: false }

  onSubmitted = () => this.setState({ submitted: true })

  render() {
    const { submitted } = this.state
    return (
      <Container>
        <Title>Carrier Registration</Title>
        {submitted ? <Success /> : <Form onSubmitted={this.onSubmitted} />}
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 60px;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
  width: calc(60% - 120px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: ${props => props.theme.desktop}px) {
    width: calc(100% - 60px);
    margin: 15px 30px 0;
  }
`
const Title = styled.h3`
  padding: 10px 0;
  font-size: 18pt;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  width: 100%;
  margin-bottom: 20px;
`
