import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default () => (
  <Container>
    <TopBar>Success!</TopBar>
    <Title>Thank you for your submission.</Title>
    <Text>
      A representative is reviewing your submission and will be getting back to
      you as soon as possible. You can read more about our services by clicking
      on the links below
    </Text>
    <Links>
      <NavLink to="/haul">HAUL</NavLink>
      <NavLink to="/ship">SHIP</NavLink>
      <NavLink to="/store">STORE</NavLink>
      <NavLink to="/partner-carriers">CARRIERS</NavLink>
    </Links>
  </Container>
)

const Container = styled.div`
  font-size: 30pt;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: ${props => props.theme.primaryColor};
  background-color: white;
  border: 1px solid ${props => props.theme.primaryColor};
  padding-bottom: 60px;
  border-radius: 7px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid rgba(0, 0, 0, 0.3);

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 90%;
  }
`
const TopBar = styled.div`
  border-radius: 5px 5px 0 0;
  width: 100%;
  padding: 25px;
  font-size: 30pt;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
  background-color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};
`
const Title = styled.h1`
  width: 100%;
  padding: 50px 0 20px;
  font-size: 20pt;
  text-align: center;
  letter-spacing: -1px;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};
`
const Text = styled.p`
  width: 80%;
  margin: 0 auto;
  font-size: 16pt;
  text-align: center;
  letter-spacing: -1px;
  line-height: 22pt;
  color: ${props => props.theme.fontColor};
  font-weight: ${props => props.theme.book};
`
const Links = styled.div`
  width: 95%;
  margin: 30px auto 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: ${props => props.theme.xl}px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 70%;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 90%;
  }
`
const NavLink = styled(Link)`
  width: 20%;
  padding: 25px;
  font-size: 14pt;
  text-align: center;
  letter-spacing: -1px;
  line-height: 0;
  color: ${props => props.theme.primaryColor};
  background-color: transparent;
  font-weight: ${props => props.theme.demi};
  border: 2px solid ${props => props.theme.primaryColor};
  border-radius: 5px;
  transition-duration: 0.5s;

  @media (max-width: ${props => props.theme.xl}px) {
    width: 30%;
    margin: 15px;
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 30%;
    margin: 15px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 40%;
    margin: 15px;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: 60%;
    margin: 10px 25px;
  }

  &:hover {
    color: ${props => props.theme.accentColor1};
    background-color: ${props => props.theme.primaryColor};
  }
`
