import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import NavBar from 'components/NavBar'
import Header from 'components/Header'
import PartnerCarriers from './PartnerCarriers'
import CarrierOpportunities from './CarrierOpportunities'

export const PartnerCarriersPageTemplate = ({ header, section1, section2 }) => (
  <Fragment>
    <Header
      images={header.images}
      title={header.title}
      subtitle={header.subtitle}
      anchor={header.scrollAnchor}
    />
    <PartnerCarriers
      title={section1.title}
      text={section1.text}
      anchor={section1.scrollAnchor}
    />
    <CarrierOpportunities
      title={section1.opportunities.title}
      textBlock1={section1.opportunities.text1}
      list={section1.opportunities.list}
      textBlock2={section1.opportunities.text2}
    />
  </Fragment>
)

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, structuredData } = frontmatter
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
        structuredData={structuredData}
      />
      <Helmet title={seo.title} />
      <NavBar />
      <PartnerCarriersPageTemplate
        seo={frontmatter.seo}
        structuredData={frontmatter.structuredData}
        header={frontmatter.header}
        section1={frontmatter.section1}
        section2={frontmatter.section2}
      />
    </Layout>
  )
}

export const query = graphql`
  query PartnerCarriersPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seo {
          title
          description
          url
        }
        structuredData {
          context
          type
          url
          name
          address
          telephone
          priceRange
          additionalProperty
          openingHours
          areaServed
          email
          legalName
          alternateName
          description
        }
        header {
          images {
            mobile {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            desktop {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          title
          subtitle
          scrollAnchor
        }
        section1 {
          scrollAnchor
          title
          text
          opportunities {
            title
            text1
            list {
              item
            }
            text2
          }
        }
        section2 {
          image {
            childImageSharp {
              sizes(maxWidth: 2000) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          title
          button {
            path
            text
          }
        }
      }
    }
  }
`
