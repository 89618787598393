import React, { Fragment } from 'react'
import styled from 'styled-components'
import ScrollAnchor from 'components/ScrollAnchor'

export default ({ title, text, anchor }) => (
  <Fragment>
    <ScrollAnchor anchor={anchor}>
      <Title>{title}</Title>
    </ScrollAnchor>
    <TextContainer>
      <Text>{text}</Text>
    </TextContainer>
  </Fragment>
)
const Title = styled.div`
  width: 100%;
  padding: 40px 0;
  letter-spacing: -2px;
  text-align: center;
  font-size: 55pt;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};

  @media (max-width: ${props => props.theme.desktop}px) {
    font-size: 40pt;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    font-size: 25pt;
  }
`
const TextContainer = styled.div`
  width: 100%;
  padding: 70px 0;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${props => props.theme.desktop}px) {
    padding: 40px 0;
  }
`
const Text = styled.p`
  text-align: center;
  width: 60%;
  font-size: 20px;
  line-height: 28pt;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.demi};

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 80%;
  }
`
